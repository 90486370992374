import React from 'react'

import Error from '../components/Error'
import Section from '../components/Section'

export default () => (
  <Section>
    <Error />
  </Section>
)
